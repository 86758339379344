import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import Dashboard from "components/Dashboard"
import DashboardKelas from "components/Dashboard/Kelas"

const DashboardKelasWishlistPage = () => (
  <Layout noFooter>
    <SEO title="Dashboard Wishlist" />
    <Dashboard chosen="Kelas">
      <DashboardKelas />
    </Dashboard>
  </Layout>
)

export default DashboardKelasWishlistPage
